import React from 'react';
import './App.css';

function App() {
  return (
    <main>
      <img
        alt="..."
        className="logo"
        src={require("./Theros-horizontal.png")} />
    </main>
  );
}

export default App;
